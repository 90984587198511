import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Layout from '../../components/layout'

import {
	Container,
	Section,
	Row,
	Column,
	ScrollableContainer,
} from '../../components/layouts'

import {
	CardForm,
	OneTimeDonate,
	ThankYouMsj,
} from '../../components/ui'

import {
	processOneTimeDonation,
	clearErrors,
} from '../../factory'

import { getUrlParams } from '../../util'

import theme from '../../theme/theme'

const {
	oneTimeDonation,
} = theme.sections

class OneTimeDonation extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			position: 0,
			selected: null,
			customActive: false,
			customValue: 0,
			valid: false,
			projectId: null,
			orgId: null,
			token: null,
			key: null,
			error: '',
		}

		this.setUrlParams = this.setUrlParams.bind(this)
		this.selectDonateAmount = this.selectDonateAmount.bind(this)
		this.setCustomDonateAmount = this.setCustomDonateAmount.bind(this)
		this.validation = this.validation.bind(this)
		this.submitForm = this.submitForm.bind(this)
		this.nextPosition = this.nextPosition.bind(this)
		this.prevPosition = this.prevPosition.bind(this)
	}

	componentDidMount() {
		this.props.clearErrors()
		this.setUrlParams()
	}

	setUrlParams() {
		const queryURL = getUrlParams(window.location.search)
		this.setState({
			selected: 5,
			customActive: false,
			customValue: 0,
			projectId: queryURL.project_id,
			orgId: queryURL.organization_id || queryURL.organization_token,
			token: queryURL.token,
			key: queryURL.key,
			redirect: queryURL.redirect || 'flourish://'
		})
	}

	selectDonateAmount(e) {
		if (Number(e.target.value)) {
			const value = Number(e.target.value)

			if (value > 0 || value === -1) {
				this.setState({
					customActive: value === -1 ? true : false,
					selected: value,
				})
			}
		}
	}

	setCustomDonateAmount(e) {
		if (Number(e.target.value)) {
			const value = Number(e.target.value)

			if (value > 0 || value === -1) {
				this.setState({
					customValue: value,
				})
			}
		}
	}

	validation() {
		if (!this.state.selected) {
			this.setState({
				error: 'Select a donation amount.',
			})

			return false
		} else if (!this.state.token && !this.state.key && !this.state.orgId && !this.state.projectId) {
			this.setState({
				error: 'Please ensure you came to this page from the app.'
			})

			return false
		}

		this.setState({
			error: '',
			valid: true,
		})

		return true
	}

	submitForm(e) {
		e.preventDefault()

		const amount = this.state.customActive ? this.state.customValue : this.state.selected

		if (this.validation()) {
			const { key, orgId, projectId, token } = this.state

			this.props.processOneTimeDonation(token, key, amount, projectId, orgId, () => {
				if (!this.state.error && !this.props.error) {
					this.nextPosition()
				}
			})
		}
	}

	nextPosition() {
		if (this.state.position === 2) {
			this.setState({
				loadDonate: false,
			})
		}

		if (this.state.position >= 0) {
			this.setState({
				position: this.state.position < 1 ? this.state.position + 1 : this.state.position,
			})
		}
	}

	prevPosition() {
		this.setState({
			position: this.state.position > 0 ? this.state.position - 1 : this.state.position,
		})
	}

	render() {
		return (
			<Layout>
				<Section bg={oneTimeDonation.bg} paddingTop={'8em'} paddingBottom={'3em'}>
					<form>
						<ScrollableContainer position={this.state.position} totalPositions={2}>
							<Container maxWidth={'840px'}>
								<Row>
									<Column>
										<CardForm>
											<OneTimeDonate
												title={oneTimeDonation.forms.title}
												subtitle={oneTimeDonation.forms.subtitle}
												values={oneTimeDonation.forms.values}
												onChange={this.selectDonateAmount.bind(this)}
												onCustomChange={this.setCustomDonateAmount.bind(this)}
												isCustom={this.state.customActive}
												selectedOption={this.state.selected}
												submitBtn={oneTimeDonation.forms.submitBtn}
												validated={this.state.valid}
												errors={this.state.error || this.props.error}
												submit={this.submitForm.bind(this)}
												loading={this.props.pending}
												redirect={'flourish://'}
											/>
										</CardForm>
									</Column>
								</Row>
							</Container>
							<Container maxWidth={'840px'}>
								<Row>
									<Column>
										<CardForm>
											<ThankYouMsj
												title={oneTimeDonation.forms.thanks.title}
												description1={oneTimeDonation.forms.thanks.description1}
												description2={oneTimeDonation.forms.thanks.description2}
												image={oneTimeDonation.forms.thanks.image}
												note={oneTimeDonation.forms.thanks.note}
												giveBtn={oneTimeDonation.forms.thanks.giveBtn}
												homeBtn={oneTimeDonation.forms.thanks.homeBtn}
												backToStart={this.prevPosition}
												redirect={this.state.redirect}
											/>
										</CardForm>
									</Column>
								</Row>
							</Container>
						</ScrollableContainer>
					</form>
				</Section>
			</Layout>
		)
	}
}

OneTimeDonation.propTypes = {
	error: PropTypes.any,
	pending: PropTypes.bool,
	processOneTimeDonation: PropTypes.func,
	clearErrors: PropTypes.func,
}

const mapStateToProps = (state) => {
	return {
		error: typeof state.donateNow.error === 'object' && state.donateNow.error ? Object.values(state.donateNow.error) : (state.donateNow.error ? state.donateNow.error : null),
		pending: state.donateNow.pending,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		processOneTimeDonation: (token, key, sum, projId, orgId, callBack) => dispatch(processOneTimeDonation(token, key, sum, projId, orgId, callBack)),
		clearErrors: () => dispatch(clearErrors()),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OneTimeDonation)
